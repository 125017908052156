
/**
 * @description Array containing the delivery data for each conveyor.
 *
 * @author Stefane DR <sdr@wshop.com>
 */
let arrayDeliveryData = {};

/**
 * Initializes the calendar for delivery date selection.
 *
 * @author  Stefane DR  <sdr@wshop.com>
 *
 * @param {string} conveyorId - The ID of the conveyor.
 *
 * @returns {void}
 */
function initCalendar(conveyorId) {

    var inputTarget = $('#delivery-date-' + conveyorId);
    if (inputTarget.length) {
        var deliveryData = arrayDeliveryData[conveyorId] || {};
        let isPreco = deliveryData.isPreco || '';

        if (inputTarget.attr('conveyor-id')) {
            conveyorId = inputTarget.attr('conveyor-id');
        }

        let dateToday = getTodayDate();

        let minDate = dateToday;

        // we check rules depending on the current hour and the conveyor
        checkHourlyRules(conveyorId);

        // initialize the datepicker
        inputTarget.datepicker({
            dateFormat: 'dd/mm/yy',
            minDate: minDate,
            maxDate: new Date(dateToday.getFullYear(), dateToday.getMonth() + 3, 0),
            altField: '#order_delivery_date',
            altFormat: 'yy-mm-dd',
            firstDay: 1,
            beforeShowDay: function (date) {
                return (conveyorId == '24' && !isPreco)
                    ? enablePickupDate(date, conveyorId)
                    : enableDeliveryDate(date, conveyorId);
            },
        });
    }
}

/**
 * We check if we have availability for this month
 *
 * @author  Youssef Mabrouk  <yma@wshop.com>
 *
 * @returns {void}
 */
function checkAvailibiltyCurrentMonth() {
    let dateToday = getTodayDate();
    var currentDay = dateToday;
    var nbrDaysOfCurrentMonth = this.daysInMonth(currentDay.getMonth() + 1, currentDay.getFullYear());// "+1" because "getMonth" starts counting at 0, and goes until 11.
    var nbrDaysOfCurrentMonthUnselected = document.getElementsByClassName("ui-datepicker-unselectable").length - document.getElementsByClassName("ui-datepicker-other-month").length;
    var isNoAvailibilty = (nbrDaysOfCurrentMonth == nbrDaysOfCurrentMonthUnselected) ? true : false;
    if (isNoAvailibilty) {
        document.querySelector('.ui-datepicker-next').click();
    }
}

/**
 * We check the number of days of the current month. If we use the "year" param, it's because the specificity of february.
 *
 * @author  Youssef Mabrouk  <yma@wshop.com>
 *
 * @param   {integer}   month
 * @param   {integer}   year
 *
 * @returns {Date}
 */
function daysInMonth (month, year) {
    return new Date(year, month, 0).getDate();
}

/**
 * Determines if a given delivery date is enabled based on various conditions.
 *
 * @param {Date} date - The date to check for delivery availability.
 * @param {number} [conveyorId=0] - The ID of the conveyor (default is 0).
 *
 * @returns {Array|boolean} - Returns an array with a boolean indicating if the date is enabled, or false if the date is not enabled.
 *
 * @author  Stefane DR  <sdr@wshop.com>
 */
function enableDeliveryDate(date, conveyorId = 0) {
    let deliveryData = arrayDeliveryData[conveyorId] || {};
    let dateToday = getTodayDate();

    // we retrieve the data from the deliveryData array
    let arrayDates = deliveryData.disabledDates ? deliveryData.disabledDates.split('|') : [];

    let isPreco = deliveryData.isPreco || '';
    let remiseStock = deliveryData.remiseStock || '';
    let grandeBuche = deliveryData.isGrandeBuche || '0';
    let petiteBuche = deliveryData.isPetiteBuche || '0';
    let patisserie = deliveryData.isPatisserie || '0';
    let patisserie2 = deliveryData.isPatisserie2 || '0';
    let galette = deliveryData.isGalette || '0';
    let hasUltraFrais = deliveryData.hasUltraFrais || '0';
    let entremet = deliveryData.isEntremet || '0';

    // we recheck the store ID here ta take the shop change in count
    var storeIdNoel = $('#livr_relais').val();
    if (!storeIdNoel) {
        storeIdNoel = $('[name="livr_dom"]').val();
    }
    storeIdNoel = Number(storeIdNoel);

    // specifique date for the christmas pastry
    const { petiteBucheDates, grandeBucheDates } = getBucheDates(storeIdNoel);
    const sharedDates = getAvailableDates(storeIdNoel, galette, entremet);
    const galetteDates = sharedDates;
    const entremetDates = sharedDates;
    if (isPreco == "1") {
        var datePreco = $.datepicker.parseDate('dd/mm/yy', remiseStock);
        datePreco.setDate(datePreco.getDate() - 1);
        var formattedDatePrecoYear = $.datepicker.formatDate('yy-mm-dd', datePreco);
    }

    var formatDate = $.datepicker.formatDate('mm-dd', date);

    if (formatDate == $.datepicker.formatDate('mm-dd', dateToday)) {
        return false;
    }

    var formatDateYear = $.datepicker.formatDate('yy-mm-dd', date);
    var currentDay = date.getDay();
    conveyorId = (conveyorId != 0) ? conveyorId : getCurrentConveyor();
    var specialDates = ['04-09', '04-10', '04-15'];

    // "Saturday" or "Sunday" aren't working days for PM
    if (isWeekendDay(currentDay) && conveyorId != 24) {
        return false;
    }

    // if it's a pre-order
    if (isPreco == '1') {
        if (formatDateYear < formattedDatePrecoYear) {
            return false;
        }
    }

    // If the selected conveyor is "Chrono Fresh"
    if (conveyorId == '51') {
        var arrayDaysHigh = [0, 1];
        var arrayDaysLow = [0, 1, 2, 4, 6];

        // enable all days except weekends
        if (formatDate >= '02-01' && formatDate <= '03-31') {
            return [arrayDates.indexOf(formatDate) == -1 && arrayDates.indexOf(formatDateYear) == -1];
        } else if (formatDate >= '09-01' && formatDate <= '06-30') {
            // if it's the "high season" we don't show : Sundays and Mondays
            if (arrayDaysHigh.includes(currentDay)) {
                return false;
            }
        } else if (formatDate >= '01-01' && formatDate <= '01-31') {
            // for january we show all working days
            if (isWeekendDay(currentDay)) {
                return false;
            }
        } else if (formatDate >= '07-01' && formatDate <= '08-31') {
            // if it's the "low season" we only show : Wednesdays and Thursdays
            if (arrayDaysLow.includes(currentDay)) {
                return false;
            }
        }

        // until 06/10, the following days are deactivated: Sunday / Monday / Tuesday / Saturday
        // SW-17984 - DO NOT DELETE
        // if (formatDate < '10-06' && formatDate > '09-04' && [0, 1, 2, 6].includes(currentDay) && !specialDates.includes(formatDate)) {
        //     return false;
        // }
    }

    // condition for "Ultra frais" products
    if (hasUltraFrais == '1') {
        var previousDay = formatDate - 1;
        if (currentDay == 1 || arrayDates.includes(previousDay)) {
            return false;
        }
    }

    // Check if the current date falls within the range of December 1st (12-01) to January 31st (01-31).
    // This condition is used to apply specific rules or behaviors for dates during the holiday season.
    if (formatDate >= '12-01' && formatDate <= '01-31') {
        var arrayPreviousDates = [];
        var previousDayInitial = $.datepicker.parseDate('mm-dd', formatDate);
        previousDayInitial.setDate(previousDayInitial.getDate() - 1);
        arrayPreviousDates.push($.datepicker.formatDate('mm-dd', previousDayInitial));
        // SW-16340 - DO NOT DELETE
        // if (['51', '37'].includes(conveyorId)) { 
        //     previousDayInitial.setDate(previousDayInitial.getDate() - 1);
        //     arrayPreviousDates.push($.datepicker.formatDate('mm-dd', previousDayInitial));
        // }

        if (arrayDates.some(v => arrayPreviousDates.indexOf(v) !== -1) && !specialDates.includes(formatDate)) {
            return false;
        }
    }

    if (conveyorId == '24') {
        // Conditions for "Petite Buche"
        if (petiteBuche == '1') {
            if (!petiteBucheDates.includes(formatDate)) {
                return false; // Disable if date is not in valid dates
            }
        }
        if (galette == '1') {
            if (!galetteDates.includes(formatDate)) {
                return false; // Disable if date is not in valid dates
            }
        }
        if (entremet == '1') {
            if (!entremetDates.includes(formatDate)) {
                return false; // Disable if date is not in valid dates
            }
        }

        // Conditions for "Grande Buche"
        if (grandeBuche == '1') {
            if (!grandeBucheDates.includes(formatDate)) {
                return false; // Disable if date is not in valid dates
            }
        }

        // Conditions for Pastry (delivery on D+1 before 12 p.m.)
        if (patisserie == '1') {
            var tomorrow = new Date();
            tomorrow.setDate(date.getDate() + 1);
            var tomorrowFormatted = $.datepicker.formatDate('yy-mm-dd', tomorrow);

            // If the order is placed after 12 p.m., deactivate D+1
            if (new Date().getHours() >= 12 && formatDateYear == tomorrowFormatted) {
                return false;
            }
        }
        if (patisserie2 == '1') {
            var tomorrow = new Date(); 
            tomorrow.setDate(date.getDate() + 1);
            var tomorrowFormatted = $.datepicker.formatDate('yy-mm-dd', tomorrow);
            // Convert to Date object
            let parsedDate = new Date(formatDate);
            // Get the day of the week
            let day = parsedDate.getDay();
            // Disable Mondays (1) and Tuesdays (2)
            if (day == 1 || day == 2) {
                return false;
            }

            // If the order is placed after 12 p.m., deactivate D+1
            if (new Date().getHours() >= 12 && formatDateYear == tomorrowFormatted) {
                return false;
            }
        }
    }
    // Return array of valid dates
    return [arrayDates.indexOf(formatDate) == -1 && arrayDates.indexOf(formatDateYear) == -1];
}

/**
 * @description Check hourly rules for PM's conveyors
 *
 * @author  Stefane DR  <sdr@wshop.com>
 *
 * @param {string}  conveyorId
 *
 * @returns {void}
 */
function checkHourlyRules(conveyorId) {
    let dateToday = getTodayDate();
    var hours = dateToday.getHours();
    var arrayChronopost = ['51', '37'];
    var arrayFedex = ['36', '54'];
    var arrayDpd = ['52', '53'];
    let deliveryData = arrayDeliveryData[conveyorId] || {};
    let arrayDates = deliveryData.disabledDates ? deliveryData.disabledDates.split('|') : [];

    // if the selected conveyor is "ChronoFresh" or "ChronoExpress"
    if (arrayChronopost.includes(conveyorId)) {
        // add D+1
        dateToday.setDate(dateToday.getDate() + 1);
        // let verifyDate = verifyDateIntegrity(dateToday, conveyorId);
        // if (typeof verifyDate === 'boolean' && !verifyDate
        //     || typeof verifyDate === 'object' && !verifyDate[0]
        // ) {
        //     dateToday.setDate(dateToday.getDate() + 1);
        // }
        arrayDates.push($.datepicker.formatDate('yy-mm-dd', dateToday));
        arrayDates = checkWorkingDays(dateToday, arrayDates);
        if (hours >= 12) {
            // add D+2
            dateToday.setDate(dateToday.getDate() + 1);
            // let verifyDate = verifyDateIntegrity(dateToday, conveyorId);
            // if (typeof verifyDate === 'boolean' && !verifyDate
            //     || typeof verifyDate === 'object' && !verifyDate[0]
            // ) {
            //     dateToday.setDate(dateToday.getDate() + 1);
            // }
            arrayDates.push($.datepicker.formatDate('yy-mm-dd', dateToday));
            arrayDates = checkWorkingDays(dateToday, arrayDates);
        }
    } else if (arrayFedex.includes(conveyorId) || arrayDpd.includes(conveyorId)) {
        arrayDates.push($.datepicker.formatDate('yy-mm-dd', dateToday));
        arrayDates = checkWorkingDays(dateToday, arrayDates);
        if (hours >= 8) {
            // add D+1
            dateToday.setDate(dateToday.getDate() + 1);
            // let verifyDate = verifyDateIntegrity(dateToday, conveyorId);
            // if (typeof verifyDate === 'boolean' && !verifyDate
            //     || typeof verifyDate === 'object' && !verifyDate[0]
            // ) {
            //     dateToday.setDate(dateToday.getDate() + 1);
            // }
            arrayDates.push($.datepicker.formatDate('yy-mm-dd', dateToday));
            arrayDates = checkWorkingDays(dateToday, arrayDates);
        }
    }

    deliveryData.disabledDates = arrayDates.join('|');
}

/**
 * Checks if current day is a working day
 *
 * @author  Stefane DR  <sdr@wshop.com>
 *
 * @param   {Datetime}  date
 * @param   {Array}     array
 *
 * @returns {Array}
 */
function checkWorkingDays(date, array) {
    currentDay = date.getDay();
    while (isWeekendDay(currentDay)) {
        date.setDate(date.getDate() + 1);
        currentDay = date.getDay();
        array.push($.datepicker.formatDate('yy-mm-dd', date));
    }
    return array;
}

/**
 * Verifies the integrity of a date according to the given rules
 *
 * @author Stefane DR <sdr@wshop.com>
 *
 * @param {string} date - The date to verify.
 * @param {string} conveyorId - The ID of the conveyor.
 *
 * @returns {boolean}
 */
function verifyDateIntegrity(date, conveyorId) {
    let dateToday = getTodayDate();
    date = new Date(date);
    dateToday.setHours(0, 0, 0, 0);
    if (date < dateToday) {
        return false;
    }

    let deliveryData = arrayDeliveryData[conveyorId] || {};
    let arrayDates = deliveryData.disabledDates ? deliveryData.disabledDates.split('|') : [];
    let isPreco = deliveryData.isPreco || '';

    let verifDate = (conveyorId == '24' && !isPreco)
        ? enablePickupDate(date, conveyorId)
        : enableDeliveryDate(date, conveyorId);
    return verifDate;
}

/**
 * Determines if a given date is available for pickup based on various conditions.
 *
 * @param {Date} date - The date to check for pickup availability.
 * @param {number} [conveyorId=0] - The ID of the conveyor (default is 0).
 *
 * @returns {boolean|Array} - Returns false if the date is not available, or an array with a boolean indicating availability.
 *
 * @author Stefane DR <sdr@wshop.com>
 */
function enablePickupDate(date, conveyorId = 0) {
    let dateToday = getTodayDate();
    let formattedDate = $.datepicker.formatDate('yy-mm-dd', date);
    let today = dateToday;
    let formattedTodayDate = $.datepicker.formatDate('yy-mm-dd', today);
    let endPeriod = getTodayDate();
    var currentDay = date.getDay();
    let parsedDate = new Date(formattedDate); 
    let dayDate = parsedDate.getDay();
    let deliveryData = arrayDeliveryData[conveyorId] || {};

    // we retrieve the data from the deliveryData array
    let petiteBuche = deliveryData.isPetiteBuche || '0';
    let grandeBuche = deliveryData.isGrandeBuche || '0';
    let patisserie = deliveryData.isPatisserie || '0';
    let patisserie2 = deliveryData.isPatisserie2 || '0';
    let galette = deliveryData.isGalette || '0';
    let entremet = deliveryData.isEntremet || '0';
    let storeClosingHours = deliveryData.storeClosingHours || '';

    let intervalDays = (petiteBuche == '1' || grandeBuche == '1' || galette == '1' || entremet == '1') ? 90 : 10;
    endPeriod.setDate( today.getDate()+ intervalDays);
    let closedDay = new Date(storeClosingHours);
    
    // Checking if the chosen date is today and the store is closed
    if (formattedDate == formattedTodayDate && today > closedDay) {
        return false; // If the store is closed, turn off today's date
    }

    // Checking the maximum collection period (10 days)
    if (date > endPeriod) {
        return false; // Disable dates beyond 10 days
    }

    // Specific dates for "Petite Buche" and "Grande Buche"
    var storeIdNoel = $('#livr_relais').val();
    if (!storeIdNoel) {
        storeIdNoel = $('[name="livr_dom"]').val();
    }
    storeIdNoel = Number(storeIdNoel);
    const { petiteBucheDates, grandeBucheDates } = getBucheDates(storeIdNoel);
    const galetteDates = getAvailableDates(storeIdNoel, galette, entremet);
    const entremetDates = getAvailableDates(storeIdNoel, galette, entremet);
    // Date format for comparison with specific dates
    var formatDate = $.datepicker.formatDate('mm-dd', date);

    // Initialize a variable for return
    let isValidDate = true;

    // Condition for "Petite Buche"
    if (petiteBuche == '1') {
        // If the selected date is not in the valid dates for Petite Bûche
        if (!petiteBucheDates.includes(formatDate)) {
            isValidDate = false; // Disable if the date is not valid for Petite Bûche
        }
    }

    // Condition for "Galette"
    if (galette == '1') {
        // If the selected date is not in the valid dates for Petite Bûche
        if (!galetteDates.includes(formatDate)) {
            isValidDate = false; // Disable if the date is not valid for Petite Bûche
        }
    }

        // Condition for "entremet"
        if (entremet == '1') {
            // If the selected date is not in the valid dates for Petite Bûche
            if (!entremetDates.includes(formatDate)) {
                isValidDate = false; // Disable if the date is not valid for Petite Bûche
            }
        }

    // Condition for "Grande Bûche"
    if (grandeBuche == '1') {
        // If the selected date is not in the valid dates for Grande Bûche
        if (!grandeBucheDates.includes(formatDate)) {
            isValidDate = false; // Disable if the date is not valid for Grande Bûche
        }
    }

    // Condition for pastry
    if (patisserie == '1') {
        let todayDate =  new Date();
        todayDate.setDate(today.getDate());
        let todayFormattedDate = $.datepicker.formatDate('yy-mm-dd', todayDate);
        // Check if today is before 12 p.m
        if (today.getHours() < 12) {
            let tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 1);
            let formattedTomorrowDate = $.datepicker.formatDate('yy-mm-dd', tomorrow);
            // If the selected date is not tomorrow, it is valid
            if (formattedDate != formattedTomorrowDate && formattedDate === todayFormattedDate) {
                isValidDate = false; // Disable if we try to choose tomorrow
            }
        } else {
            // If after 12 p.m., deactivate tomorrow
            let tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 1);
            let formattedTomorrowDate = $.datepicker.formatDate('yy-mm-dd', tomorrow);
            if (formattedDate == formattedTomorrowDate || formattedDate === todayFormattedDate) {
                isValidDate = false; // Disable if we try to choose tomorrow after 12 p.m.
            }
        }
    }

    // Condition for pastry
    if (patisserie2 == '1') {
        let todayDate =  new Date();
        todayDate.setDate(today.getDate());
        let todayFormattedDate = $.datepicker.formatDate('yy-mm-dd', todayDate);
        if (today.getHours() < 12) {
            let tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 1);
            let formattedTomorrowDate = $.datepicker.formatDate('yy-mm-dd', tomorrow);
            if (dayDate == 1 || dayDate == 2) {
                isValidDate = false; 
            }
            // If the selected date is not tomorrow, it is valid
            if (formattedDate != formattedTomorrowDate && formattedDate === todayFormattedDate) {
                isValidDate = false; // Disable if we try to choose tomorrow
            }

        } else {
            // If after 12 p.m., deactivate tomorrow
            let tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 1);
            let formattedTomorrowDate = $.datepicker.formatDate('yy-mm-dd', tomorrow);
            if (formattedDate == formattedTomorrowDate || formattedDate === todayFormattedDate) {
                isValidDate = false; // Disable if we try to choose tomorrow after 12 p.m.
            }
            if (dayDate == 1 || dayDate == 2) {
                isValidDate = false; 
            }
        }
    }
    
    if (isValidDate && date <= endPeriod) {
        // Checking if the store is exceptionally open on the selected day
        let storeExceptionalDays = deliveryData.storeExceptionalDays ? deliveryData.storeExceptionalDays.split('|') : [];
        if (storeExceptionalDays.includes(formattedDate)) {
            return [true]; // Enable if the store is exceptionally open on the selected day
        }

        // Checking if the store is closed on the selected day
        let storeClosingDays = deliveryData.storeClosingDays ? deliveryData.storeClosingDays.split('|') : [];
        if (storeClosingDays.includes('7')) {
            storeClosingDays.push('0');
        }

        if (storeClosingDays.includes(currentDay.toString())) {
            return false; // Disable if the store is closed on the selected day
        }

        let arrayDeliveryDates = deliveryData.disabledDates ? deliveryData.disabledDates.split('|') : [];
        if (arrayDeliveryDates.includes(formattedDate)) {
            return false; // Disable if the date is in the disabled dates
        }
    }
    

    // Return array of valid dates
    return [isValidDate && date <= endPeriod];
}

/**
 * Determine the first available for delivery date
 *
 * @param conveyorId
 * @param isPreco
 *
 * @returns {null|Date}
 *
 * @author Souvanny <ssi@wshop.com>
 */
function determinesFirstAvailableDeliveryDate(conveyorId, isPreco) {
    let startDate = new Date();
    let endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 2); // arbitrary value

    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        let date = currentDate;
        let v = (conveyorId == '24' && !isPreco)
            ? enablePickupDate(date, conveyorId)
            : enableDeliveryDate(date, conveyorId);

        if (v === true || (Array.isArray(v) && v.length === 1 && v[0] === true)) {
            return currentDate;
        }

        currentDate.setDate(currentDate.getDate() + 1);
    }

    return null;
}

/**
 * Retrieves the available dates for petite and grande buche based on the store ID.
 *
 * @author Jordan HOURI <jh@wshop.com>
 *
 * @param {number} storeId - The ID of the store.
 * @returns {Object} An object containing two arrays:
 * - petiteBucheDates {string[]} - The dates available for petite buche.
 * - grandeBucheDates {string[]} - The dates available for grande buche.
 */
function getBucheDates(storeId) {
    let petiteBucheDates = [];
    let grandeBucheDates = [];

    if ([45, 42, 54, 48, 44].includes(storeId)) {
        petiteBucheDates = ['12-07', '12-08', '12-13', '12-14', '12-15', '12-20', '12-21', '12-22', '12-23', '12-24', '12-31'];
        grandeBucheDates = ['12-13', '12-14', '12-15', '12-20', '12-21', '12-22', '12-23', '12-24', '12-31'];
    } else if ([13, 60, 38].includes(storeId)) {
        petiteBucheDates = ['12-23', '12-24'];
        grandeBucheDates = ['12-23', '12-24'];
    }

    const now = getTodayDate();

    const dayOfWeek = now.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
    const hour = now.getHours();
    const minute = now.getMinutes();
    const isAfterFridayNoon = (dayOfWeek === 5 && (hour > 12 || (hour === 12 && minute > 0))); // friday after 12h01
    const isSaturday = dayOfWeek === 6;
    const isSunday = dayOfWeek === 0;
    const today = formatDate(now);
    const isAfterThursdayNoon = (dayOfWeek === 4 && (hour > 12 || (hour === 12 && minute > 0))); // thursday after 12h01
    // Filter only Fridays present in dates
    const fridayDates = petiteBucheDates.filter(date => isFriday(date, now));
    // Calculate specific days to disable
    let excludeDates = [];

    if (isAfterThursdayNoon) {
        excludeDates.push(getNextDate(now, 1)); 
    }

    if (isAfterFridayNoon) {
        excludeDates.push(getNextDate(now, 0)); // friday
        excludeDates.push(getNextDate(now, 1)); // saturday
        excludeDates.push(getNextDate(now, 2)); // sunday
        excludeDates.push(getNextDate(now, 3)); // monday
    } else if (isSaturday) {
        excludeDates.push(getNextDate(now, 0)); // saturday
        excludeDates.push(getNextDate(now, 1)); // sunday
        excludeDates.push(getNextDate(now, 2)); // monday
    } else if (isSunday) {
        excludeDates.push(getNextDate(now, 0)); // sunday
        excludeDates.push(getNextDate(now, 1)); // monday
    } else if (dayOfWeek === 1) {
        excludeDates.push(getNextDate(now, 0)); // monday
    }
    excludeDates.push(getNextDate(now, 0)); // Lundi
    // Filter dates to exclude specific days and past dates
    const excludeDateStrings = excludeDates.map(date => formatDate(date));
    petiteBucheDates = petiteBucheDates.filter(date => !excludeDateStrings.includes(date) && date >= today);
    grandeBucheDates = grandeBucheDates.filter(date => !excludeDateStrings.includes(date) && date >= today);

    return { petiteBucheDates, grandeBucheDates };

    // Helper: Get a specific date (D+N)
    function getNextDate(baseDate, daysToAdd) {
        const nextDate = new Date(baseDate);
        nextDate.setDate(nextDate.getDate() + daysToAdd);
        return nextDate;
    }

    // Helper: Format a date as MM-DD
    function formatDate(date) {
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${month}-${day}`;
    }

    // Helper: Check if a date in 'MM-DD' corresponds to a Friday
    function isFriday(dateStr, currentDate) {
        const [month, day] = dateStr.split('-').map(Number);
        const date = new Date(currentDate.getFullYear(), month - 1, day);
        return date.getDay() === 5; // 5 correspond is friday
    }
}

/**
 * Fetches the delivery date data for a specific store and updates the delivery data array.
 *
 * @param {number} storeId - The ID of the store to fetch delivery data for.
 * @param {number} conveyorId - The ID of the conveyor to update delivery data for.
 *
 * @returns {void}
 *
 * @author Stefane DR <sdr@wshop.com>
 */
function updateStoreDeliveryData(storeId, conveyorId) {
    $.ajax({
        url: path_relative_root + create_link('ajax_get_store_delivery_data'),
        type: "get",
        data: {store_id: storeId},
        success: function(response) {
            var response = JSON.parse(response);

            if (response.success) {
                var store = response.store;

                arrayDeliveryData[conveyorId].disabledDates = (store.disabledDates !== undefined) ? store.disabledDates : '';
                arrayDeliveryData[conveyorId].storeId = (store.storeId !== undefined) ? store.storeId : '';
                arrayDeliveryData[conveyorId].storeClosingDays = (store.storeClosingDays !== undefined) ? store.storeClosingDays : '';
                arrayDeliveryData[conveyorId].storeClosingHours = (store.storeClosingHours !== undefined) ? store.storeClosingHours : '';
                arrayDeliveryData[conveyorId].storeExceptionalDays = (store.storeExceptionalDays !== undefined) ? store.storeExceptionalDays : '';
            } else {
                alert('Erreur : ' + Translator.translate(response.error));
            }
        }
    })
}

/**
 * Returns the next Monday from the given date.
 *
 * @description If the given date is a Saturday, it returns the Monday after the next, the returned date will have the time set to 07:00:00.
 *
 * @param {Date|string|number} date - The initial date. Can be a Date object, a date string, or a timestamp.
 *
 * @returns {Date} The next Monday with the time set to 07:00:00.
 *
 * @author Stefane DR <sdr@wshop.com>
 */
function getNextMonday(date) {
    date = new Date(date);
    var day = date.getDay();
    var diff = date.getDate() - day;
    if (day == 6) {
        diff += 8;
    } else {
        diff += 1;
    }

    let nextMonday = new Date(date.setDate(diff));
    nextMonday.setHours(7);
    return nextMonday;
}

/**
 * Determines if a given day corresponds to a weekend (Saturday or Sunday).
 *
 * @param {number|string} day - The day of the week as a number (0-6) or a string that can be converted to a number. 0 represents Sunday, 1 represents Monday, ..., 6 represents Saturday.
 * @returns {boolean} Returns `true` if the day is Saturday (6) or Sunday (0), otherwise `false`.
 *
 * @author Stefane DR <sdr@wshop.com>
 */
function isWeekendDay(day) {
    day = Number(day);
    if (isNaN(day)) {
        return false;
    }
    // Check if the day is Saturday (6) or Sunday (0)
    return [6, 0].includes(day);
}

/**
 * Retrieves the current date. If the URL contains a 'test_date' parameter and the hostname includes 'localhost' or 'preprod',
 * it returns the date specified in the 'test_date' parameter. Otherwise, it returns the current date.
 *
 * @returns {Date} The current date or the date specified in the 'test_date' URL parameter.
 *
 * @example /livraison-choix.php?test_date=2022-12-24+12:15:10 set the current date to the 24th of December 2022 at 12:15:10.
 *
 * @author Stefane DR <sdr@wshop.com>
 */
function getTodayDate() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    let dateToday = (urlParams.has('test_date') && (window.location.hostname.includes('localhost') || window.location.hostname.includes('preprod')))
        ? new Date(urlParams.get('test_date'))
        : new Date();

    let conveyorId = getCurrentConveyor();
    // we check if it's a weekend and we set the date to the next monday
    if (isWeekendDay(dateToday.getDay()) && conveyorId != 24) {
        dateToday = getNextMonday(dateToday);
    }
    return dateToday;
}

/**
 * Retrieves the available dates for galettes and entremets based on the store ID and rules.
 *
 * @author Jordan HOURI <jh@wshop.com>
 *
 * @param {number} storeId - The ID of the store.
 * @param {boolean} galette - Whether the cart contains a galette.
 * @param {boolean} entremet - Whether the cart contains an entremet.
 * @returns {string[]} An array of available dates.
 */
function getAvailableDates(storeId, galette, entremet) {
    let availableDates = [];

    // specific dates for galette and entremet
    const productDates = {
        galette: ['01-03', '01-04', '01-05', '01-06', '01-08', '01-09', '01-10', '01-11', '01-12'],
        entremet: ['04-16', '04-17', '04-18', '04-19', '04-20']
    };

    // Shop specific for galette and entremet
    const specialStores = [45, 42, 54, 48, 44];

    if (specialStores.includes(storeId)) {
        if (galette) availableDates.push(...productDates.galette);
        if (entremet) availableDates.push(...productDates.entremet);
    }

    const now = getTodayDate();
    const currentYear = now.getFullYear();
    const dayOfWeek = now.getDay();
    const hour = now.getHours();
    const minute = now.getMinutes();

    const isAfterFridayNoon = (dayOfWeek === 5 && (hour > 12 || (hour === 12 && minute > 0)));
    const isSaturday = dayOfWeek === 6;
    const isSunday = dayOfWeek === 0;
    const isAfterNoon = hour >= 12;

    let excludeDates = [];

    // exclude today
    excludeDates.push(now);
    // exclude rules
    if (isAfterNoon) excludeDates.push(getNextDate(now, 1));
    if (isAfterFridayNoon) {
        excludeDates.push(getNextDate(now, 1));
        excludeDates.push(getNextDate(now, 2));
        excludeDates.push(getNextDate(now, 3));
    } else if (isSaturday) {
        excludeDates.push(getNextDate(now, 0));
        excludeDates.push(getNextDate(now, 1));
        excludeDates.push(getNextDate(now, 2));
    } else if (isSunday) {
        excludeDates.push(getNextDate(now, 0));
        excludeDates.push(getNextDate(now, 1));
    } else if (dayOfWeek === 1) {
        excludeDates.push(getNextDate(now, 0));
    }

    // convert dates to strings for comparison
    const excludeDateStrings = excludeDates.map(date => formatDateWithYear(date));

    //filter available dates
    availableDates = availableDates.filter(date => {
        const dateObj = parseDateWithYear(date, currentYear);
        return dateObj && !excludeDateStrings.includes(formatDateWithYear(dateObj)) && dateObj >= now;
    });

    return availableDates;

    // Helper: Get a specific date (D+N)
    function getNextDate(baseDate, daysToAdd) {
        const nextDate = new Date(baseDate);
        nextDate.setDate(nextDate.getDate() + daysToAdd);
        return nextDate;
    }

    // Helper: Format date  MM-DD
    function formatDate(date) {
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${month}-${day}`;
    }

    // Helper: Format date YYYY-MM-DD
    function formatDateWithYear(date) {
        return `${date.getFullYear()}-${formatDate(date)}`;
    }

    // Helper: convert date string to Date object with year
    function parseDateWithYear(dateStr, baseYear) {
        const [month, day] = dateStr.split('-').map(Number);
        if (!month || !day) return null;

        const dateThisYear = new Date(baseYear, month - 1, day);
        if (month < now.getMonth() + 1) {
            return new Date(baseYear + 1, month - 1, day);
        }
        return dateThisYear;
    }
}

/**
 * Retrieves the current conveyor ID from the DOM element with the ID `conveyor_id`.
 * Converts the value to a number before returning it.
 *
 * @returns {number} The numeric ID of the current conveyor.
 *
 * @author Stéfane DR <sdr@wshop.com>
 */
function getCurrentConveyor() {
    let conveyorId = $('#conveyor_id').val();
    return Number(conveyorId);
}
